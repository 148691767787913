
.headerActions {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: 100%;
  padding: 0px 12px;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
}

.headerNotifications {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: x-large;
  height: 100%;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
}

.headerNotifications span {
  font-size: inherit!important;
}

.headerActions span {
  margin-left: 6px;
  margin-right: 6px;
}

.headerLeftContent {
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.headerRightContent {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  float: right;
  height: 48px;
  margin-left: auto;
  overflow: hidden;
}


.headerRightContent ul {
  border: none;
}

.layoutHeader {
  align-items: center;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 21, 41, 0.08) 0px 1px 4px;
  display: flex;
  height: 100%;
  padding: 0px 16px;
  position: relative;
}

.mainLayoutHeader {
  background: #fff;
  height: 48px;
  line-height: 48px;
  padding: 0px;
  width: 100%;
  z-index: 19;
}

.navButton {
  appearance: none;
  background-color: transparent;
  border-radius: 6px;
  border: 0px;
  color: rgb(110, 117, 159);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.75;
  margin: 0px;
  min-width: 64px;
  outline: 0px;
  padding: 6px 8px;
  text-decoration: none;
  text-transform: none;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  vertical-align: middle;
}

.avatar {
  margin-left: 8px;
  color: #f56a00;
  background-color: #fde3cf;
  font-size: 14px;
  font-weight: bold;
}