.eventsFilterContainer {
  margin-bottom: 10px;
}
.filterActionContainer {
  padding: 20px;
  margin: 20px 0px;
  border-bottom: 1px solid #DDD;
  display: flex;
  justify-content: flex-end;
}
