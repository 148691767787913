.headliner,
.headliner:hover,
.headliner:focus,
.headliner:active {
  background: yellowgreen!important;
  color: #000;
}

.multiSelectHeader {
  background-color: #ddd;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  width: 100%;
}

.multiSelectList {
  float: left;
  padding: 0px;
  width: 100%;
}

.multiSelectListItem {
  cursor: pointer;
  margin-bottom: 0;
  padding: 4px 10px!important;
  text-transform: capitalize;
}

.priceClassLegend {
  cursor: pointer;
  margin-bottom: 0;
  padding: 4px 10px!important;
  text-transform: capitalize;
}

.legendA {
  background: #EA3323;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendB {
  background: #CF9896;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendB\+ {
  background: #B25751;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendC {
  background: #2F6EBA;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendC\+ {
  background: #A8B5C8;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendD {
  background: #000;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendD\+ {
  background: #000;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendUltra {
  background: #4EAD5B;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendUL {
  background: #4EAD5B;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.legendA\+ {
  background: #EA9B56;
  display: block;
  float: left;
  height: 15px;
  width: 25px;
}

.multiSelectHighlightListItem {
  color: #e91e63!important;
  cursor: pointer;
  font-weight: bolder;
  margin-bottom: 0;
  padding: 4px 10px;
  text-transform: capitalize;
}

.highlightListItem {
  color: #e91e63!important;
  cursor: pointer;
  font-weight: bolder;
  margin-bottom: 0;
  padding: 4px 10px!important;
}

.multiSelectFilter {
  float: left;
  height: 32px;
  padding: 0px!important;
  width: 100%;
}

.selectionRow a:first-child {
  margin-left: 6px;
  margin-right: 6px;
}

.selectionRow a:last-child {
  margin-left: 6px;
}

.selectionRow {
  margin-top: 6px;
}

.selectionRow button {
  padding-left: 2px;
  padding-right: 2px;
}

.delimiter{
  margin-top: 4px;
}

.switchImage {
  margin: auto;
  padding-left: 4%;
}
