.cardContent {
  box-sizing: border-box;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.tile {
  align-items: center!important;
  display: flex;
}

.thumbnail {
  height: 4.25rem;
  width: 4.25rem;
}

.thumbnail img {
  border-radius: .375rem!important;
  height: 100%!important;
  object-fit: cover;
  width: 100%!important;
}

.mediaBody {
  flex: 1;
  margin-left: 1rem!important;
}

.eventName {
  color: #344050!important;
  font-size: 15px !important;
  font-weight: 600!important;
}

.description {
  font-size: 13px;
}

.description span{
  color: #748194!important;
}

.mediaBody h4 {
  margin: 0;
}

.list ul{
  border: 0;
  height: 100%;
  padding-right: 18px;
}

.progressBar {
  position: absolute;
  right: 12px;
}
