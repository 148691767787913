.imageHolder {
  margin: 10px 0px
}

.uploadButton {
  margin-bottom: 12px;
  margin-right: 20px;
  padding: 10px 0px;
  width: 150px;
}

.cloudinaryThumbnailImage {
  width: 150px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: cover;
}
