.dashboard-layout-background {
  background: #fff;
  padding: 24px;
  min-height: 600px;
}

.dashboard-content {
  margin: 24px 16px 0;
}

.header {
  color: rgb(34, 51, 84);
  font-size: 21px;
  font-size: 5rem;
  font-weight: 700;
  line-height: 1.4;
  margin: 0;
  margin: 0px 0px 4px;
}

.rowContent {
  margin: 24px;
  row-gap: 0px;
  width: 100%;
}

.eventsCount {
  color: rgba(0,0,0,.85);
  font-size: 30px;
  height: 38px;
  line-height: 38px;
  margin-bottom: 0;
  margin-top: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.card {
  min-height: 420px;
}

.cardBody {
  padding: 8px;
}
