.button {
  background: #efefef;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.eventsSelector {
  margin: 20px 0px;
}

.orgLayoutBackground {
  background: #fff;
  padding: 24px;
  min-height: 100px;
  margin: 20px 0px;
}

.divider {
  margin: 8px;
  margin-left: 0px;
}

.label {
  display: inline-block;
  margin-bottom: 12px;
  margin-top: 12px;
}

.addButton {
  position: absolute!important;
  right: 12px;
  top: 12px;
}

.password {
  min-width: 350px!important;
}

.applicationsContainer {
  margin-bottom: 12px;
}

.space {
  width: 100%;
  margin-top: 24px;
}

.card {
  box-shadow: 0 0 5px 5px rgb(0 0 0 / 3%);
  border-radius: 16px!important;
  display: flex;
  flex-direction: column;
}