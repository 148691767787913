.label {
  padding: 5px 10px 5px 0px;
  font-size: 14px;
  font-weight: normal;
}

.inline-label {
  padding-right: 10px;
  padding-top: 4px;
  float: left;
  font-size: 14px;
  font-weight: normal;
}
