
.root {
  -ms-flex-pack: center;
  align-items: center;
  background-size: cover;
  background: #fff;
  color: #001529;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  min-width: 100%;
  text-align: center;
  align-self: center;
  padding: 30px;
  width: 100%;
}

.header {
  color: #001529;
  font-size: 5rem;
  margin: 0;
}

.subHeader {
  color: #001529;
  font-size: 1.8em;
  margin: 20px 0 30px;
}
