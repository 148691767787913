
.ant-collapse-header {
  color: rgba(0, 0, 0, 0.85);
  cursor: move!important;
  cursor: pointer;
  line-height: 1.5715;
  padding: 12px 16px;
  position: relative;
  transition: all 0.3s, visibility 0s;
}

.ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  font-size: 12px;
  margin-right: 12px;
  vertical-align: -1px;
}

.ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
