.button {
  background: #efefef;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-style: outset;
  border-width: 2px;
  margin: 12px;
}

.divider {
  margin: 8px;
}

.sectionTemplate {
  border: 1px solid dodgerblue;
  margin-right: 6px;
}