.updateActionButton {
  margin-left: 12px;
}

.editPromoCodeLayoutBackground {
  background: #fff;
  padding: 24px;
  min-height: 100px;
  margin: 20px 0px;
}
