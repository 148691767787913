.logoPreview {
  background: #DDD;
}

.logosContainer {
  display: flex;
  flex-direction: row
}

.logoPreviewContainer {
  display: flex;
  flex-direction: column;
  width: 350px
}

.statusFlagsContainer {
  display: flex;
  padding: 20px 0px;
}

.actionContainer {
  padding: 20px 0px;
}

.actionContainer button {
  margin-right: 20px;
  width: 150px;
}

.uploaderLabel {
  padding: 5px 0px;
}

.logoSelector {
  width: 300px;
  padding: 20px 0px;
}
