.button {
  background: #efefef;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-style: outset;
  border-width: 2px;
}

.eventsSelector {
  margin: 20px 0px;
}

.sponsorsLayoutBackground {
  background: #fff;
  margin: 20px 0px;
  min-height: 100px;
  padding: 24px;
}
