
.ant-carousel .slick-dots li button {
  background: #001529;
  border-radius: 100%;
  height: 6px;
  opacity: 0.4;
  width: 6px;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #001529;
  border-radius: 100%;
  height: 7px;
  opacity: 1;
  width: 7px;
}
