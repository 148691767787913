.ant-layout-sider, .ant-layout-sider-trigger {
  min-width: 220px !important;
}

.sidebar-collapsed {
  min-width: 80px !important;
}

.sidebar-collapsed .ant-layout-sider-trigger {
  min-width: 80px!important;
}