.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  min-height: 42px;
  color: #111827;
  font-size: 16px;
  background: transparent;
  font-weight: 600;
  line-height: 32px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  background: rgba(255, 255, 255, 0.3);
  float: left;
  height: 31px;
  margin: 16px 24px 16px 0;
  width: 120px;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.white-background {
  background-color: white;
}

.ant-image-img {
  height: 34px;
  max-height: 35px;
  max-width: 210px;
  vertical-align: middle;
}

.layout-header, .layout-content {
  padding: 0;
}

.ant-layout .headline {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 30px;
  margin: 20px;
}

.ant-layout .headline a {
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
}

.logo {
  background: rgba(255, 255, 255, 0.2);
  height: 32px;
  margin: 16px;
}

.site-layout-background {
  background: #fff;
  min-height: 600px;
  padding: 24px;
}

.ant-layout-header {
  padding: 0px;
}

.ant-layout-footer {
  background: #fff;
  padding: 0px;
}

.main-container {
  min-height: 100vh;
}

.main-content {
  margin: 24px 16px 0;
}