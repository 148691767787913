.eventsLayoutBackground {
  background: #fff;
  padding: 24px;
  min-height: 600px;
}

.events-content {
  margin: 24px 16px 0;
}

.logoPreview {
  background: #00f;
  margin-top: 12px;
}
