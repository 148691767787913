
.menu-icon svg {
  vertical-align: initial;
}

.menuItem {
  composes: menu-icon;
}

.main-menu {
  background: #fff;
}


.menuContainer {
  composes: main-menu;
}
