.root {
  display: flex;
  flex-direction: column;
}

.controls {
  align-items: center;
  display: flex;
  margin-bottom: 1rem;
}

.filter {
  width: auto;
  min-width: 200px;
  margin-right: 18px!important;
}

.search {
  margin-left: auto;
  width: 400px!important;
}

.tableEntries {
  bottom: 40px;
  position: relative;
  white-space: nowrap;
  width: 250px;
}
