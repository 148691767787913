.label {
  text-transform: uppercase;
}

.label .ant-form-item-label {
  text-align: left;
}

.row {
  margin-bottom: 12px;
}

.helperText {
  margin-left: 4px;
}