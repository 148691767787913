.deleteIcon {
  color: #c12336;
  font-size: 16px;
}

.imageDisclaimer {
  font-style: italic;
  line-height: 1.5;
  padding: 0 !important;
}

.errorText {
  color: #c12336;
  margin-right: 12px;
  margin: auto;
  text-align: right;
  transition: opacity .3s;
  width: 100%;
}

.draggableCard {
  background: #fff;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  cursor: move;
  margin-bottom: 10px;
  opacity: 1;
  padding: 5px;
}

.divider {
  margin: 12px 0px;
}
