.ant-list-header,
.ant-list-footer {
  padding-bottom: 0px!important;
}

.ant-list-items,
.ant-list-empty-text {
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-radius: 0 0 6px 6px;
  border-right: 1px solid #f0f0f0;
  height: 20em;
  line-height: 2em;
  margin-right: 20px;
  margin: 0;
  overflow-x: hidden;
  overflow: auto;
  padding: 0;
  width: 100%;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}

.ant-input-prefix {
  margin: 8px;
}
