:root {
  --width-90: "90%"
}

.component-width-90 {
  width: var(--width-90)
}

.pullDownTimePickerWidth {
  width: 196px;
}
