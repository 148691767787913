.primary {
  border-color: #3642b1!important;
}

.secondary {
  border-color: #6dc33b!important;
}

.tertiary {
  border-color: #8d2a8d!important;
}

.promoItem {
  border-color: #d9d9d9!important;
}

.deleteIcon {
  font-size: 16px;
  /* margin-bottom: 20px; */
}

