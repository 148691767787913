.eventsLayoutBackground {
  background: #fff;
  padding: 24px;
  min-height: 600px;
}

.events-content {
  margin: 24px 16px 0;
}

.updateActionButton, .deleteActionButton {
  margin-left: 12px;
}
