.button {
  background: #efefef;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
}

.eventsSelector {
  margin: 20px 0px;
}

.priceTemplatesLayoutBackground {
  background: #fff;
  padding: 24px;
  min-height: 100px;
  margin: 20px 0px;
}

.alignCenter .ant-button {
  left: 45%
}

.addButton {
  background: #efefef;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-style: outset;
  border-width: 2px;
  font-size: 15px!important;
  font-weight: bolder!important;
  height: 42px!important;
  left: 50%;
  outline: auto!important;
}

.priceTemplateLegends {
  float: right;
}

.priceTemplateLegends ul {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
}

.priceTemplateLegends ul li {
  display: block;
  float: left;
  width: 50px;
  margin-bottom: 6px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  list-style: none;
}

.priceTemplateLegends .priceClassA {
  background: #EA3323;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassB {
  background: #CF9896;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassB\+ {
  background: #B25751;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassC {
  background: #2F6EBA;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassC\+ {
  background: #A8B5C8;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassD\+ {
  background: #4e4b51;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassD {
  background: #000;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassUltra {
  background: #4EAD5B;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}

.priceTemplateLegends .priceClassA\+ {
  background: #EA9B56;
  display: block;
  float: left;
  height: 15px;
  width: 50px;
}
