.button {
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-style: outset;
  border-width: 2px;
  margin-top: 18px;
}

.marqueeLayoutBackground {
  background: #fff;
  margin: 20px 0px;
  min-height: 100px;
  padding: 24px;
}

.column {
  width: 345px;
}

.previewImageContainer {
  width: 150px;
  height: 150px;
  display: inline-block;
}

.previewImageContainer img {
  width: inherit;
  height: inherit;
}

.previewImageContainer .closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
}
