.file-list {
  background-color: rgb(195, 230, 155);
  border: 1px solid rgb(12, 230, 0);
  padding: 16px 16px 16px 0;
  position: relative;
}

.file-list:hover {
  cursor: pointer;
}

.file-list:hover > span > span {
  background-color :rgb(134, 228, 25);
  border: 1px solid transparent;
  border-radius: 5px;
}

.file-list ul {
  margin: 0;
}

.file-list span {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 4px;
  border: 1px solid transparent;
}

.form-row {
  margin: 16px 0;
}

.form-row label {
  margin-right: 8px;
}

.form-row input[type="text"] {
  border: 1px solid #bbb;
  outline: none;
  padding: 2px 4px;
}

.error-message {
  background-color: rgb(255, 173, 156);
  border: 1px solid rgb(253, 45, 0);
  padding: 16px;
  margin-bottom: 24px;
}