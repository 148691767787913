:root {
  --tag-bg-color: #e6f7ff;
  --tag-border-color: #91d5ff;
  --tag-delete-text-color: #666;
  --tags-list-bg-color: #096dd9;
  --tag-delete-hover-text-color: #cc0000;
  --tag-delete-hover-bg-color: #ffdddd;
  --white-color: #fff;
  --tag-lookup-text-color: #eee;
  --tag-lookup-li-color: #eeeeff;
}

.tags-input {
  position: relative;
}

.tags-list {
  padding: 0;
  margin-bottom: 0px;
}

.tags-list li {
  color: var(--tags-list-bg-color);
  background-color: var(--tag-bg-color);
  border: 1px solid var(--tag-border-color);
  cursor: pointer;
  float: left;
  list-style-type: none;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 3px 5px;
  border-radius: 5px;
}

.tags-list li .delete {
  background-color: transparent;
  border-style: none;
  color: var(--tag-delete-text-color);
  display: none;
  font-size: 11px;
  height: 11px;
  margin-left: 5px;
  padding: 0;
  width: 11px;
}

.tags-list li:hover {
  background-color: var(--tag-delete-hover-bg-color);
  color: var(--tag-delete-hover-text-color);
  text-decoration: line-through;
}

.tags-list:after {
  clear: both;
  content: '';
  display: block;
}

.tags-lookup {
  background-color: var(--white-color);
  border: 1px solid var(--tag-lookup-text-color);
  border-top-style: none;
  padding: 0;
  position: absolute;
  z-index: 100;
}

.tags-lookup li {
  background-color: transparent;
  cursor: pointer;
  list-style-type: none;
  padding: 5px 15px 5px 5px;
}

.tags-lookup li:hover {
  background-color: var(--tag-lookup-li-color);
}

.tags-lookup li:hover::after {
  content: '+';
  position: absolute;
  right: 5px;
}

.tags-error {
  color: #ff4d4f;
}