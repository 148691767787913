.preference-layout-background {
  background: #fff;
  margin: 20px 0px;
  min-height: 100px;
  padding: 24px;
}

.preference-content {
  margin: 24px 16px 0;
}

.actionContainer {
  position: absolute;
  right: 30px;
  top: 30px;
}

div.panel {
  font-size: 16px;
  font-weight: 600;
}

.disclaimer {
  color: rgb(220, 0, 78)!important;
  display: block;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
}

.title {
  color: #3c4858!important;
  font-weight: bold;
  line-height: 1.1;
  margin: auto;
  width: 100%;
  border: 1px solid #2A2D3B!important;
}

.deleteIcon {
  font-size: 16px;
  /* margin-bottom: 20px; */
}
