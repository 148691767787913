.htmlEditorHeader {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.htmlEditorContainer {
  border: 1px solid #d9d9d9;
}

.htmlTextContainer, .previewContainer {
  border-radius: 0px;
  min-height: 170px;
  outline: none;
  padding: 10px;
}

.errorHtmlEditorContainer {
  border: 1px solid #ff4d4f;
}

.htmlEditorActionButton {
  border: 1px solid #d9d9d9;
  outline: none;
}

.htmlTextContainer:focus, .previewContainer:focus {
  border: 1px solid #1890ff;
  border-right-width: 1px !important;
  outline: none;
}
