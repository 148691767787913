.actionContainer {
  position: absolute;
  right: 30px;
  top: 22px;
}

.pricetemplatesLayout {
  background: #fff;
  padding: 24px;
  min-height: 600px;
}
