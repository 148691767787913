.admin-layout-background {
  background: #fff;
  min-height: 600px;
  padding: 24px;
}

.adminContent {
  margin: 24px 16px 0;
}

.submitButton {
  margin: 10px 0px;
  width: 300px;
}

.uploadButton {
  margin: 10px 0px;
  width: 300px;
}
