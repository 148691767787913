.drop-box {
  display: flex;
  width: 100%;
  height: 250px;
  background-color: #ccc;
  border: 1px solid #bbb;
  padding: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
}

.drop-box:hover {
  cursor: pointer;
}

.drop-box p {
  margin-bottom: 0;
}

.drop-box input[type="file"] {
  visibility: hidden;
  display: block;
  width: 0;
  height: 0;
}