.collapsePanel {
  background-color: #fff;
  border: 0;
  margin: 12px 0px!important;
}

.collapsePanel .ant-collapse-arrow {
  color: #000;
  font-size: 16px;
}

.collapseBorderedPanel {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  margin: 8px 0px!important;
}

.textAlignRight {
  text-align: right;
}

.hasError {
  border-color: #ff4d4f!important;
}
