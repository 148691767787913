:root {
  --grey-color: #eee;
  --height-32: 32;
  --padding-12: 12;
}

.sectionName {
  background: var(--grey-color);
  height: calc(var(--height-32) * 1px);
  padding-left: 6px;
  padding-top: 6px;
}

.sectionContainer {
  padding-bottom: 0;
  padding-left: 0;
  padding: calc(var(--padding-12) * 1px);
}

.sectionFormInputItem {
  margin-bottom: 0;
}

.sectionFormInputItemErr {
  border: red 1px solid!important;
  margin-bottom: 0;
}
